export class CalcularNota {
      // Funcoes Auxiliares de CALCULO das notas dos alunos


    //Bimestre
    static async calcMediaBimestral (record, periodo, substituiOque, configuracao) {
        var atividade = 0, simulado = 0, avaliacao = 0, recuperacao = 0;
        if (periodo == 1) {
            if (record.periodo1_atividades != null && configuracao.info.tera_atividades) {
                atividade = parseInt(record.periodo1_atividades[0].atividade);
            }
            if (record.periodo1_simulado != null && configuracao.info.tera_simulado) {
                simulado = parseInt(record.periodo1_simulado.simulado);
            }
            if (record.periodo1_avaliacao != null && configuracao.info.tera_avaliacao) {
                avaliacao = parseInt(record.periodo1_avaliacao.avaliacao);
            }
            if (record.periodo1_recuperacao != null && configuracao.info.tera_recuperacao) {
                recuperacao = parseInt(record.periodo1_recuperacao.recuperacao);
            }
        }
        else if (periodo == 2) {
            if (record.periodo2_atividades != null && configuracao.info.tera_atividades) {
                atividade = parseInt(record.periodo2_atividades[0].atividade);
            }
            if (record.periodo2_simulado != null && configuracao.info.tera_simulado) {
                simulado = parseInt(record.periodo2_simulado.simulado);
            }
            if (record.periodo2_avaliacao != null && configuracao.info.tera_avaliacao) {
                avaliacao = parseInt(record.periodo2_avaliacao.avaliacao);
            }
            if (record.periodo2_recuperacao != null && configuracao.info.tera_recuperacao) {
                recuperacao = parseInt(record.periodo2_recuperacao.recuperacao);
            }

        }
        else if (periodo == 3) {
            if (record.periodo3_atividades != null && configuracao.info.tera_atividades) {
                atividade = parseInt(record.periodo3_atividades[0].atividade);
            }
            if (record.periodo3_simulado != null && configuracao.info.tera_simulado) {
                simulado = parseInt(record.periodo3_simulado.simulado);
            }
            if (record.periodo3_avaliacao != null && configuracao.info.tera_avaliacao) {
                avaliacao = parseInt(record.periodo3_avaliacao.avaliacao);
            }
            if (record.periodo3_recuperacao != null && configuracao.info.tera_recuperacao) {
                recuperacao = parseInt(record.periodo3_recuperacao.recuperacao);
            }
        }
        else if (periodo == 4) {
            if (record.periodo4_atividades != null && configuracao.info.tera_atividades) {
                atividade = parseInt(record.periodo4_atividades[0].atividade);
            }
            if (record.periodo4_simulado != null  && configuracao.info.tera_simulado) {
                simulado = parseInt(record.periodo4_simulado.simulado);
            }
            if (record.periodo4_avaliacao != null  && configuracao.info.tera_avaliacao) {
                avaliacao = parseInt(record.periodo4_avaliacao.avaliacao);
            }
            if (record.periodo4_recuperacao != null  && configuracao.info.tera_recuperacao) {
                recuperacao = parseInt(record.periodo4_recuperacao.recuperacao);
            }
        }
        // a recuperacao substitui tudo.
        if (substituiOque == 7)
            return  Math.max(atividade + simulado + avaliacao, recuperacao);
        //  a recuperacao substitui atividade e avaliacao.
        if (substituiOque == 6)
            return  simulado + Math.max(atividade + avaliacao, recuperacao);
        //  a recuperacao substitui simulado e avaliacao.
        if (substituiOque == 5)
            return  atividade + Math.max(simulado + avaliacao, recuperacao);
        //  a recuperacao substitui atividade e simulado.
        if (substituiOque == 4)
            return  avaliacao + Math.max(atividade + simulado, recuperacao);
        //  a recuperacao substitui a avaliacao.
        if (substituiOque == 3)
            return  simulado + atividade + Math.max(avaliacao, recuperacao);
        //  a recuperacao substitui a atividade.
        if (substituiOque == 2)
            return  simulado + avaliacao + Math.max(atividade, recuperacao);
        //  a recuperacao substitui o simulado
        if (substituiOque == 1)
            return atividade + avaliacao + Math.max(simulado, recuperacao);
    }
    static async caclMediaFinalTipoBimestral (total, record, tipoTabela) {
        var recuperacao = 0, totalMedia = 0;
        if (tipoTabela == 3)// se for eja tem apenas dois periodos
            totalMedia = Math.round(parseInt(total) / 2);
        else 
            totalMedia = Math.round(parseInt(total) / 4); // se nao for eja tem mais

        if (record.aluno_notas_completa_existe) {
            if (record.aluno_notas_completa.recuperacao_final != null)
                recuperacao = parseInt(record.aluno_notas_completa.recuperacao_final);
        }
        return  Math.max(totalMedia, recuperacao);
    }



    //Semestral
    //Retorna a maior nota
   /* static async calcMediaSemestral (nota1, nota2, notaRecuperacao) {
        return (parseFloat(nota1) + parseFloat(nota2)  >= notaRecuperacao ? parseFloat(nota1) + parseFloat(nota2) : parseFloat(notaRecuperacao));
    }*/
   
    static async caclMediaFinalTipoSemestral (total, recuperacao, tipoTabela) {
        if (recuperacao == null)
            recuperacao = 0;
        recuperacao = parseInt(recuperacao);

        var media = '';
        if (tipoTabela == 3) // se for eja tem apenas dois periodos
            media = Math.round(parseInt(total) / 2);
        else
            media = Math.round(parseInt(total) / 4); // se nao for eja tem mais
        return Math.max(media, parseInt(recuperacao));
    }

    static async calcTotalPeriodoSemestral (record, periodo, configuracao) {
        var atividade = 0, simulado = 0, avaliacao = 0;
        if (periodo == 1) {
            if (record.periodo1_atividades != null) {
                atividade = parseInt(record.periodo1_atividades[0].atividade);
            }
            if (record.periodo1_simulado != null) {
                simulado = parseInt(record.periodo1_simulado.simulado);
            }
            if (record.periodo1_avaliacao != null) {
                avaliacao = parseInt(record.periodo1_avaliacao.avaliacao);
            }
        }
        else if (periodo == 2) {
            if (record.periodo2_atividades != null) {
                atividade = parseInt(record.periodo2_atividades[0].atividade);
            }
            if (record.periodo2_simulado != null) {
                simulado = parseInt(record.periodo2_simulado.simulado);
            }
            if (record.periodo2_avaliacao != null) {
                avaliacao = parseInt(record.periodo2_avaliacao.avaliacao);
            }
        }
        else if (periodo == 3) {
            if (record.periodo3_atividades != null) {
                atividade = parseInt(record.periodo3_atividades[0].atividade);
            }
            if (record.periodo3_simulado != null) {
                simulado = parseInt(record.periodo3_simulado.simulado);
            }
            if (record.periodo3_avaliacao != null) {
                avaliacao = parseInt(record.periodo3_avaliacao.avaliacao);
            }
        }
        else if (periodo == 4) {
            if (record.periodo4_atividades != null) {
                atividade = parseInt(record.periodo4_atividades[0].atividade);
            }
            if (record.periodo4_simulado != null) {
                simulado = parseInt(record.periodo4_simulado.simulado);
            }
            if (record.periodo4_avaliacao != null) {
                avaliacao = parseInt(record.periodo4_avaliacao.avaliacao);
            }
        }
       /// return atividade+simulado+avaliacao;
        let soma = 0;
        if (configuracao.info.tera_simulado){
          soma += simulado;
        }
        if (configuracao.info.tera_atividades){
          soma += atividade;
        }
        if (configuracao.info.tera_avaliacao){
          soma += avaliacao;
        }

        return soma;
    }

    static async calcMediaSemestral (total1, total2, recuperacao) {
        if (recuperacao == '') {
            return Math.round((total1 + total2) / 2);
        }
        if (recuperacao > total1 && recuperacao > total2) {
            return recuperacao;
        }
        else {
            let notas = [];
            notas.push(total1, total2, recuperacao);
            notas.sort(function (a, b) {return a - b;});

            return Math.round((notas[1] + notas[2]) / 2);
        }
    }
    static async aRecuperacaoSubstituiOQue (total1, total2, recuperacao) {
        //Retornar 1 altera tudo, retorna 2 altera só o primeiro, retorna 3 altera o segundo
        if (recuperacao > total1 && recuperacao > total2) {
            return 1;
        }
        else if (recuperacao > total1) {
            return 2;
        }
        else if (recuperacao > total2) {
            return 3;
        }
    }
}