<template>
  <section class="pm-card resp-notatable" >
    <div >
      <b-tabs content-class="mt-3"  v-model="periodoSelecionado2">
        <b-tab :title="'1° Período'" :title-link-class="linkClass()">
          <PeriodoTable
              :isLoadingPage="isLoadingPage"
              v-if="configuracao != null"
              :final="false"
              :periodoSelecionado="periodoSelecionado2+1"
              :acesso="acesso"
              :encerrado="encerrado"
              :displayedRecords="displayedRecords"
              :configuracao="configuracao"
              @ajustarInfo="ajustarInfo"
              @adicionarEditarSimulado="adicionarEditarSimulado"
              @adicionarEditarAtividade="adicionarEditarAtividade"
              @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
              @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
              @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
          />
        </b-tab>
        <b-tab title="2° Período" :title-link-class="linkClass()">
          <PeriodoTable
             :isLoadingPage="isLoadingPage"
              v-if="configuracao != null"
              :final="false"
              :periodoSelecionado="periodoSelecionado2+1"
              :acesso="acesso"
              :encerrado="encerrado"
              :displayedRecords="displayedRecords"
              :configuracao="configuracao"
              @ajustarInfo="ajustarInfo"
              @adicionarEditarSimulado="adicionarEditarSimulado"
              @adicionarEditarAtividade="adicionarEditarAtividade"
              @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
              @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
              @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
          />
        </b-tab>
        <b-tab title="3° Período" >
          <PeriodoTable
             :isLoadingPage="isLoadingPage"
              v-if="configuracao != null"
              :final="false"
              :periodoSelecionado="periodoSelecionado2+1"
              :acesso="acesso"
              :encerrado="encerrado"
              :displayedRecords="displayedRecords"
              :configuracao="configuracao"
              @ajustarInfo="ajustarInfo"
              @adicionarEditarSimulado="adicionarEditarSimulado"
              @adicionarEditarAtividade="adicionarEditarAtividade"
              @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
              @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
              @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
          />
        </b-tab>
        <b-tab title="4° Período">
          <PeriodoTable
             :isLoadingPage="isLoadingPage"
              v-if="configuracao != null"
              :final="false"
              :periodoSelecionado="periodoSelecionado2+1"
              :acesso="acesso"
              :encerrado="encerrado"
              :displayedRecords="displayedRecords"
              :configuracao="configuracao"
              @ajustarInfo="ajustarInfo"
              @adicionarEditarSimulado="adicionarEditarSimulado"
              @adicionarEditarAtividade="adicionarEditarAtividade"
              @adicionarEditarAvaliacao="adicionarEditarAvaliacao"
              @adicionarEditarRecuperacao="adicionarEditarRecuperacao"
              @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
          />
        </b-tab>
        <b-tab title="Final" >
          <PeriodoTable
              :isLoadingPage="isLoadingPage"
              :key="displayedRecords"
              v-if="configuracao != null"
              :final="true"
              :estiloTabelaEJA="estiloTabelaEJA"
              :periodoSelecionado="periodoSelecionado2+1"
              :acesso="acesso"
              :encerrado="encerrado"
              :displayedRecords="displayedRecords"
              :configuracao="configuracao"
              @ajustarInfo="ajustarInfo"
              @adicionarEditarRecuperacaoFinal="adicionarEditarRecuperacaoFinal"
              @mudarParaRetidoOuDependencia="mudarParaRetidoOuDependencia"
          />
        </b-tab>
      </b-tabs>
    </div>

    <!--<div  v-if="isLoadingPage">
      <va-progress-circle indeterminate :size="400"  style="margin-left: auto; margin-right: auto;" color="#0d89ec"/>
    </div>-->

  </section>
</template>

<script>
import { Configuracao } from "@/class/configuracao";
import { Calendario } from "@/class/calendario";
import PeriodoTable  from '@/components/notas/periodoTable.vue'
import FinalTable  from '@/components/notas/finalTable.vue'

export default {
  name: "NotasFundamentalDois",
  props: {
    tipoTabela:0,
    displayedRecords:[],
    acesso:[],
    isLoadingPage:false,
    encerrado:[],
    formAlunoComNEE:'',
    showModal:false,
    periodoSelecionado:0,
  },
  components: {
    PeriodoTable,
    FinalTable
  },
  data() {
    return {
      calendario: "",
      configuracao:null,
      periodoSelecionado2:0,
    };
  },
  beforeMount() {
    this.periodoSelecionado2 = this.periodoSelecionado,
    this.buscarConfiguracoes();
  },
  methods: {
    //buscar a configuracoes
    async buscarConfiguracoes() {
      let data1 = await Calendario.calendarioAtual();
      this.calendario = data1.data;
      let data2 = await Configuracao.buscarConfiguracaoComTipo(2, 0);
      this.configuracao = data2.data;
    },

    ajustarInfo(aluno_id){
      this.$emit('ajustarInfo', aluno_id);
    },
    adicionarEditarSimulado(aluno_id_selecionado, nota, qualTipo, idSimulado, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarSimulado', aluno_id_selecionado, nota, qualTipo, idSimulado, configuracao,periodoSelecionado);
    },
    adicionarEditarAtividade(aluno_id_selecionado, nota, qualTipo, idAtividade, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarAtividade', aluno_id_selecionado, nota, qualTipo, idAtividade, configuracao, periodoSelecionado);
    },
    adicionarEditarAvaliacao(aluno_id_selecionado, nota, qualTipo, idAvaliacao, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarAvaliacao', aluno_id_selecionado, nota, qualTipo, idAvaliacao, configuracao, periodoSelecionado);
    },
    adicionarEditarRecuperacao(aluno_id_selecionado, nota, qualTipo, idRecuperacao, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarRecuperacao', aluno_id_selecionado, nota, qualTipo, idRecuperacao, configuracao, periodoSelecionado);
    },
    mudarParaRetidoOuDependencia(record, tipo){
      this.$emit('mudarParaRetidoOuDependencia', record, tipo);
    },
    adicionarEditarRecuperacaoFinal(record, recuperacao, tipo){
      this.$emit('adicionarEditarRecuperacaoFinal', record, recuperacao, tipo);
    },

    linkClass(){
    }

  },
};
</script>


<style>
  @media(max-width:576px){
    .va-modal__container{
      width:100% !important;
      height:45%;
    }
    .va-modal--mobile-fullscreen {
      min-height: 0vh!important;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa;
    color: #495057;
    border-right: 1px solid #e9ecef;
    border-bottom: 2px solid #e9ecef;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
    vertical-align: middle;
  }

  .th_esp{
    width:15px;
    background-color: #3f448700;
    border-bottom: 2px solid #fff;
  }
  .cabecario-turma{
    background-color:#4da4e9;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .tbl_situacao_apv{
    font-weight: 600;
    color: #539b53;
  }
  .tbl_situacao_rep{
    font-weight: 600;
    color: #b93b3b;
  }
</style>

<style scoped>

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 23px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .mtwidth{
    width:10%;
  }
  .tdmt{
    text-align: center;font-weight: 800;color: #2b72fd;
  }

  tr td{
    vertical-align: middle;
  }


</style>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .th_prf{
    background-color: #0067a7 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
  }
  .th_prf1{
    background-color:  #2e92d1 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
  }
  .tbody_prf{
    border-top: 0px solid currentColor !important;
  }
</style>

<style>
 .compl{font-size: 12px !important;}
</style>
